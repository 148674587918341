<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import Editor from "@tinymce/tinymce-vue";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Konfigurasi Matrix Approval",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        editor: Editor,
    },
    data() {
        return {
            title: "Konfigurasi Surat Keputusan",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Konfigurasi Surat Keputusan",
                    active: true,
                },
            ],
            //

            axiosCatchError: null,
            mps_id: this.$route.params.mps_id,
            data_perizinan: [],
            // Componen Konfig
            mpk_menimbang: null,
            mpk_mengingat: null,
            mpk_memutuskan: null,
            mpk_salinan: null,
            mpk_memperhatikan: null,
        };
    },
    mounted() {
        // Load data awal banget
        let self = this;

        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
        });

        // get data berdasarkan id
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/get-konfigurasi-sub-sk", {
                params: {
                    mps_id: self.mps_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];

                if (response_data.meta.code == 200) {
                    self.data_perizinan = response_data_fix;
                    self.mpk_menimbang = response_data_fix.perizinan_sub_konfig_s_k.mpk_menimbang;
                    self.mpk_mengingat = response_data_fix.perizinan_sub_konfig_s_k.mpk_mengingat;
                    self.mpk_memutuskan = response_data_fix.perizinan_sub_konfig_s_k.mpk_memutuskan;
                    self.mpk_salinan = response_data_fix.perizinan_sub_konfig_s_k.mpk_salinan;
                    self.mpk_memperhatikan = response_data_fix.perizinan_sub_konfig_s_k.mpk_memperhatikan;
                    Swal.close();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                Swal.close();
            });
    },
    methods: {
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/store-konfigurasi-sub-sk",
                    {
                        mpk_mps_id: self.data_perizinan.mps_id,
                        mpk_menimbang: self.mpk_menimbang,
                        mpk_mengingat: self.mpk_mengingat,
                        mpk_memutuskan: self.mpk_memutuskan,
                        mpk_salinan: self.mpk_salinan,
                        mpk_memperhatikan: self.mpk_memperhatikan,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan di muat ulang.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            // self.$router.push({ name: "all-sub-perizinan" });
                            self.$router.push({ name: "all-sub-perizinan" });
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card-title">Semua Data Konfigurasi Matrix Approval Sub Perizinan</div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end"></div>
                                </div>
                                <div class="col-md-12">
                                    <div v-if="axiosCatchError">
                                        <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                            {{ axiosCatchError }}
                                        </div>
                                        <div class="alert alert-danger" role="alert" v-else>
                                            <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                                <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                                    <span class="text-danger">{{ allErrors[0] }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="alert alert-info" role="alert">
                                        <table class="table table-sm table-borderles">
                                            <tbody>
                                                <tr>
                                                    <th>Jenis Perizinan</th>
                                                    <th>:</th>
                                                    <td>
                                                        <span v-if="data_perizinan?.perizinan?.perizinanjenis?.mpj_nama">{{ data_perizinan.perizinan.perizinanjenis.mpj_nama }}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Perizinan</th>
                                                    <th>:</th>
                                                    <td>
                                                        <span v-if="data_perizinan?.perizinan?.mp_nama">{{ data_perizinan.perizinan.mp_nama }}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Sub Perizinan</th>
                                                    <th>:</th>
                                                    <td>
                                                        <span v-if="data_perizinan?.mps_nama">{{ data_perizinan.mps_nama }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <b class="text-center"> Menimbang </b>
                                            <editor
                                                api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                                :init="{
                                                    height: 500,
                                                    menubar: true,
                                                    plugins: ['table', 'code'],
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                                                }"
                                                v-model="mpk_menimbang" />
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <b class="text-center"> Mengingat </b>
                                            <editor
                                                api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                                :init="{
                                                    height: 500,
                                                    menubar: true,
                                                    plugins: ['table', 'code'],
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                                                }"
                                                v-model="mpk_mengingat" />
                                        </div>
                                        <!-- <div class="col-md-12 mb-3">
											<b class="text-center">
												Memperhatikan
											</b>
											<editor
												api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
												:init="{
													height: 500,
													menubar: true,
													plugins: [
														'table',
													],
													toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code'
												}"
												v-model="mpk_memperhatikan"
											/>
										</div> -->
                                        <div class="col-md-12 mb-3">
                                            <b class="text-center"> Memutuskan </b>
                                            <editor
                                                api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                                :init="{
                                                    height: 500,
                                                    menubar: true,
                                                    plugins: ['table', 'code'],
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                                                }"
                                                v-model="mpk_memutuskan" />
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <b class="text-center"> Salinan </b>
                                            <editor
                                                api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                                                :init="{
                                                    height: 500,
                                                    menubar: true,
                                                    plugins: ['table', 'code'],
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                                                }"
                                                v-model="mpk_salinan" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
